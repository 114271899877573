import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { sk, en } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, CircularProgress, TextField, Paper, Dialog } from '@mui/material';
import { Button } from '@mui/material';
import { styles } from './styles.js';
import { Line, Debug, GoHome, GetSlovom } from './items.js';
import { faCheck, faPrint } from '@fortawesome/free-solid-svg-icons';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';



export const QRCode = (props) => {
    const navigate = useNavigate();

    const [showButton, setShowButton] = useState(false);
    const [linkPDF, setLinkPDF] = useState('');
    const [isBusy, setBusy] = useState([]);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo = require('./react/app/logo_black.png');

    var lang = sk;
    let { func } = props;
    let params = useParams();

    useEffect(() => {
        func(false);

        GoHome();

        db_pdf();

        return () => {
        };

    }, []);

    const db_pdf = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'ziadost_pdf', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: 0,
                    tid: params.id
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {

                setLinkPDF(global.pdf + json.pdf)
                setShowButton(true);
            }

            setBusy(false);

        } catch (error) {
            //Debug(error);
            setBusy(false);
        }
    }

    return (
        <div style={{ ...styles.Block, position: 'relative', backgroundColor: global.theme_back, marginTop: 0, zIndex: 100, paddingTop: 50, paddingBottom: 150 }}>
            <div style={{ ...styles.Block, paddingBottom: 40 }}>
                <p style={{ ...styles.TextLabel, color: global.black }}>Žiadosť o výrobu destilátu</p>
            </div>
            {showButton == true ?
                <a href={linkPDF} target="_blank" style={{ ...styles.BlockRowRaw, width: 250, height: 50, backgroundColor: global.theme_dark_green, alignItems: 'center', justifyContent: 'center', textDecoration: 'none' }}>
                    <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white, marginRight: 10 }} icon={faPrint} />
                    <p style={{ ...styles.TextSmall, color: global.theme_white }}>Zobraziť žiadosť</p>
                </a>
                : null}
        </div >
    )
}