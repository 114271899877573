import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { sk } from './globals.js';
import ReCAPTCHA from 'react-google-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, CircularProgress, TextField, Paper, Dialog } from '@mui/material';
import { Button } from '@mui/material';
import { styles } from './styles.js';
import { Line, Loading, ValidateEmail, LoadLanguage, GoHome } from './items.js';
import GoogleMapReact from 'google-map-react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';



export const Contact = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [language, setLanguage] = useState(0);

    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState('');
    const [note, setNote] = useState('');
    const [error, setError] = useState('');
    const [showSended, setShowSended] = useState(false);

    const [recaptcha, setRecaptcha] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo = require('./react/app/datadream.png');
    const photo = require('./react/app/contact2.png');
    const apple = require('./react/app/logo_default.png');

    const [height, setHeight] = useState(window.innerHeight);

    var lang = sk;
    let { func } = props;

    useEffect(() => {
        func(false,2);
        GoHome();
        
        return () => {
        };

    }, []);


    /* *******************************************************************************
        POSLAT SPRAVU
    ******************************************************************************* */
    const db_send = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'message_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    mobil: mobil,
                    email: email,
                    note: note,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowSended(true);
                setName('');
                setMobil('');
                setEmail('');
                setNote('');

            } else {
                setError(lang.db_error);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(lang.db_error);
        }
    }


    const onChangeCaptcha = (value) => {
        if (value == null) {
            setRecaptcha('');
        } else {
            setRecaptcha(value);
        }
    }

    const Send = () => {
        var error = false;
        setError('');
        setNameErr('');
        setEmailErr('');

        if (name.trim() == '') {
            error = lang.required;
            setNameErr(lang.required);
        }
        if (email.trim() == '') {
            error = lang.required;
            setEmailErr(lang.required);
        } else {
            if (ValidateEmail(email) == false) {
                error = lang.email_error;
                setEmailErr(lang.email_error);
            }
        }
        if (note.trim() == '') {
            error = lang.message_text_error;
        }

        if (error != '') {
            setError(error);
        } else {
            if (recaptcha != '') {
                // VSETKO OK
                db_send();
            }
        }
    }


    return (
        <div style={{ ...styles.Block, position: 'relative', marginTop: 0, zIndex: 100 }}>
            <div style={{ ...styles.Block, backgroundColor: global.theme_back, paddingTop: 40, paddingBottom: 0, marginBottom: height }}>
                <div style={{ ...styles.BlockMaxLarge, marginTop: 50, marginBottom: 50 }}>
                    <h1 style={{ ...styles.TextXXLabel, marginTop: 10, textAlign: 'center' }}>{lang.contact_data.toUpperCase()}</h1>
                    <Line />
                </div>

                {/* COMPANY */}
                <div style={{ ...styles.BlockMaxLarge, marginTop: 50, marginBottom: 50 }} >
                    <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_gray }}>
                        <div style={{ ...styles.Block, width: '50%', padding: 20 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <img src={logo} style={{ height: 80, marginBottom: 20 }}></img>
                                <Line color={global.theme_light_gray} />
                                <p style={{ ...styles.TextLarge, color: global.theme_black, fontWeight: 'bold', marginTop: 20 }}>{global.company.name}</p>
                                <p style={{ ...styles.TextLarge, color: global.theme_black, fontWeight: 'normal', marginTop: 10 }}>{global.company.street}</p>
                                <p style={{ ...styles.TextLarge, color: global.theme_black, fontWeight: 'normal', marginTop: 3 }}>{global.company.psc} {global.company.town}</p>
                                <p style={{ ...styles.TextLarge, color: global.theme_black, fontWeight: 'normal', marginTop: 3 }}>{global.company.state}</p>
                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                    <p style={{ ...styles.TextSmall, marginTop: 2, color: global.theme_medium_gray, fontWeight: 'normal' }}>{lang.mobil.toLowerCase()}:</p>
                                    <p style={{ ...styles.TextNormal, color: global.theme_black, fontWeight: 'normal', marginLeft: 10 }}>{global.company.mobil1}</p>
                                </div>
                                <div style={{ ...styles.BlockRow, marginTop: 3 }}>
                                    <p style={{ ...styles.TextSmall, marginTop: 2, color: global.theme_medium_gray, fontWeight: 'normal' }}>{lang.email.toLowerCase()}:</p>
                                    <p style={{ ...styles.TextNormal, color: global.theme_black, fontWeight: 'normal', marginLeft: 10 }}>{global.company.email}</p>
                                </div>

                                <div style={{ ...styles.BlockRow, marginTop: 15 }}>
                                    <p style={{ ...styles.TextSmall, marginTop: 2, color: global.theme_medium_gray, fontWeight: 'normal' }}>{lang.ico}:</p>
                                    <p style={{ ...styles.TextNormal, color: global.theme_black, fontWeight: 'normal', marginLeft: 10 }}>{global.company.ico}</p>
                                </div>
                                <div style={{ ...styles.BlockRow, marginTop: 3 }}>
                                    <p style={{ ...styles.TextSmall, marginTop: 2, color: global.theme_medium_gray, fontWeight: 'normal' }}>{lang.dic}:</p>
                                    <p style={{ ...styles.TextNormal, color: global.theme_black, fontWeight: 'normal', marginLeft: 10 }}>{global.company.dic}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 40 : 0 }}>
                            <img src={photo} style={{ width: '100%', height: '100%' }} />
                        </div>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingBottom: 40 }}>

                    <Paper elevation={3} style={{ ...styles.Block, maxWidth: 500, marginTop: 50, marginBottom: 40 }}>
                        <div style={{ ...styles.Block, marginTop: 30 }}>
                            <div style={{ ...styles.Block, marginBottom: 20 }}>
                                <img src={apple} style={{ height: 80 }}></img>
                                <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 20 }}>{lang.contact_form}</p>
                            </div>
                            <p style={{ ...styles.TextSmall }}>{lang.message_question}</p>
                        </div>
                        {/* KONTAKTNE UDAJE */}
                        <div style={{ ...styles.BlockLeft, width: '95%', marginTop: 40 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 5 }}>{lang.contact_data}</p>

                            {/* MENO */}
                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <TextField size='small' error={nameErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={lang.name_surname} variant="outlined" value={name} onInput={e => setName(e.target.value)} inputProps={{ maxLength: 128 }} />
                            </div>

                            {/* MOBIL */}
                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <TextField size='small' error={mobilErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={lang.mobil} variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} inputProps={{ maxLength: 64 }} />
                            </div>

                            {/* EMAIL */}
                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <TextField size='small' error={emailErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={lang.email} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} inputProps={{ maxLength: 128 }} />
                            </div>

                        </div>

                        {/* POZNAMKA */}
                        <div style={{ ...styles.BlockLeft, width: '95%', marginTop: 40 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 5 }}>{lang.message_text}</p>

                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <TextField size='small' multiline={true} rows={4} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} inputProps={{ maxLength: 255 }} />
                            </div>
                        </div>

                        <div style={{ ...styles.Block, width: '95%', marginTop: 20 }}>
                            <ReCAPTCHA
                                sitekey="6LcBgIQeAAAAAMk_qe_tHfZjpY0vOuhtfxP1RtgP"
                                onChange={onChangeCaptcha}
                            />
                        </div>

                        <div style={{ ...styles.Block, width: '95%', height: 40, marginTop: 20, marginBottom: 20 }}>
                            {error != '' ?
                                <Alert severity="error">{error}</Alert>
                                : null}
                        </div>

                        {/* ODOSLAT */}
                        <div style={{ ...styles.Block, width: '95%', marginTop: 10, marginBottom: 20 }}>
                            <Button onClick={() => { Send() }} variant="contained" style={{ ...styles.ButtonGreen }}>{lang.send}</Button>
                        </div>

                    </Paper>

                </div>
                {
                    showSended == true ?
                        <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => setShowSended(false)}>
                            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', width: 350, height: 200 }}>
                                {/* BODY */}
                                <div style={{ ...styles.Block, width: '100%', height: '100%', backgroundColor: global.theme_white }}>
                                    <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                                        <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                                    </div>
                                    <p style={{ ...styles.TextSmall, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>{lang.message_send_od}</p>

                                </div>

                                {/* BUTTON */}
                                <div style={{ ...styles.Block, flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                                        <Button onClick={() => setShowSended(false)} variant="contained" style={{ ...styles.Button, backgroundColor: global.theme_black, width: 200 }}>{lang.close}</Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                        : null
                }

                {
                    isLoading == true ?
                        <Loading></Loading>
                        : null
                }
            </div >
        </div>
    )
}