import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate, useParams, Navigate } from "react-router-dom";
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, CircularProgress, TextField, Paper, Dialog } from '@mui/material';
import { Button } from '@mui/material';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome } from './items.js';
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { style } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ReCAPTCHA from 'react-google-recaptcha'


export const Subscribe = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState('');
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [note, setNote] = useState('');
    const [payment, setPayment] = useState(1);
    const [sended, setSended] = useState(false);
    const [price, setPrice] = useState(0);

    const [company, setCompany] = useState('');
    const [companyErr, setCompanyErr] = useState('');
    const [street, setStreet] = useState('');
    const [streetErr, setStreetErr] = useState('');
    const [psc, setPsc] = useState('');
    const [pscErr, setPscErr] = useState('');
    const [town, setTown] = useState('');
    const [townErr, setTownErr] = useState('');
    const [ico, setICO] = useState('');
    const [icoErr, setICOErr] = useState('');
    const [dic, setDIC] = useState('');
    const [dicErr, setDICErr] = useState('');

    const [price1, setPrice1] = useState('');
    const [price2, setPrice2] = useState('');
    const [price3, setPrice3] = useState('');

    const [recaptcha, setRecaptcha] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const boxWidth = 300;
    const boxHeight = boxWidth + 100;

    var lang = sk;
    let { func } = props;
    let params = useParams();

    useEffect(() => {
        func(false, 1);
        GoHome();

        setPayment(parseInt(params.type));
        db_web();
        

        if (params.type == 0) {
            setPrice(price1);
        } else {
            setPrice(price2);
        }


        return () => {
        };

    }, []);


    const db_web = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.price_month;
                tmp = tmp.toString().replace('.', ',');
                setPrice1(tmp);

                var tmp = json.price_year;
                tmp = tmp.toString().replace('.', ',');
                setPrice2(tmp);

                var tmp = json.price_year2;
                tmp = tmp.toString().replace('.', ',');
                setPrice3(tmp);
            }

            setBusy(false);

        } catch (error) {
            //Debug(error);
            setBusy(false);
        }
    }

    const db_send = async () => {
        try {
            const response = await fetch(
                global.db_url + 'licence', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: props.company_id,
                    name: name + ' ' + surname,
                    mobil: mobil,
                    email: email,
                    payment: payment,
                    price: price,
                    company: company,
                    street: street,
                    psc: psc,
                    town: town,
                    ico: ico,
                    dic: dic
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.sended == true) {
                    setSended(true);
                } else {
                    setError(lang.send_error)
                }
            } else {
                setError(lang.send_error)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const Send = () => {
        var error = false;
        setError('');
        setNameErr('');
        setSurnameErr('');
        setMobilErr('');
        setEmailErr('');
        setCompanyErr('');
        setStreetErr('');
        setPscErr('');
        setTownErr('');
        setICOErr('');
        setDICErr('');

        if (name.trim() == '') {
            error = lang.required;
            setNameErr(error);
        }

        if (surname.trim() == '') {
            error = lang.required;
            setSurnameErr(error);
        }

        if (company.trim() == '') {
            error = lang.required;
            setCompanyErr(error);
        }

        if (street.trim() == '') {
            error = lang.required;
            setStreetErr(error);
        }

        if (psc.trim() == '') {
            error = lang.required;
            setPscErr(error);
        }

        if (town.trim() == '') {
            error = lang.required;
            setTownErr(error);
        }

        if (ico.trim() == '') {
            error = lang.required;
            setICOErr(error);
        }

        if (dic.trim() == '') {
            error = lang.required;
            setDICErr(error);
        }

        if (mobil.trim() == '') {
            error = lang.required;
            setMobilErr(error);
        }

        if (email.trim() == '') {
            error = lang.required;
            setEmailErr(error);
        }

        if (recaptcha == '') {
            error = lang.captcha_error;
        }

        if (error != '') {
            setError(error);
        } else {
            // ODOSLAT SPRAVU
            db_send();
        }
    }

    const PaymentChange = (event) => {
        var value = event.target.value;
        if (value == 0) {
            setPrice(price1);
        } else {
            setPrice(price2);
        }
        setPayment(value);
    }

    const onChangeCaptcha = (value) => {
        if (value == null) {
            setRecaptcha('');
        } else {
            setRecaptcha(value);
        }
    }

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_white, position: 'relative', paddingTop: 40, paddingBottom: 40, zIndex: 100 }}>
            <div style={{ width: isSmall ? '100%' : isMedium ? 450 : 600 }}>
                {sended == false ?
                    <div style={{ ...styles.Block, width: '100%' }}>
                        <div style={{ ...styles.Block, width: '100%' }}>
                            <p style={{ ...styles.TextLabel }}>Žiadosť o zakúpenie licencie</p>

                            <div style={{ ...styles.Block, marginTop: 40 }}>
                                <RadioGroup
                                    value={payment}
                                    style={{ ...styles.BlockRow }}
                                    onChange={PaymentChange}
                                >
                                    <div style={{ ...styles.BlockRowRaw, width: '50%' }}>
                                        <Radio value={0} />
                                        <div>
                                            <p style={{ ...styles.TextTiny, marginTop: 4 }} >Mesačné predplatné</p>
                                            <p style={{ ...styles.TextXXTiny, marginTop: 0 }} >({price1} € / mesiac)</p>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRowRaw, width: '50%' }}>
                                        <Radio value={1} />
                                        <div>
                                            <p style={{ ...styles.TextTiny, marginTop: 4 }} >Celoročné predplatné</p>
                                            <p style={{ ...styles.TextXXTiny, marginTop: 0 }} >({price2} € / rok)</p>
                                        </div>
                                    </div>
                                </RadioGroup>
                            </div>

                            {/* MENO, PRIEZVISKO */}
                            <div style={{ ...styles.Block, marginTop: 20, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_back }}>
                                <div style={{ ...styles.BlockRow, width: '90%' }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', marginRight: 10 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.name}</p>
                                        <TextField error={nameErr == '' ? false : true} helperText={nameErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={name} onInput={e => setName(e.target.value)} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.surname}</p>
                                        <TextField error={surnameErr == '' ? false : true} helperText={surnameErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={surname} onInput={e => setSurname(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            {/* OBCHDNY NAZOV */}
                            <div style={{ ...styles.Block, marginTop: 40 }}>
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextLarge }}>Fakturačné údaje</p>
                                </div>
                                <div style={{ width: '90%', marginTop: 20 }}>
                                    <div style={{ ...styles.BlockLeft, }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.company_name}</p>
                                        <TextField error={companyErr == '' ? false : true} helperText={companyErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={company} onInput={e => setCompany(e.target.value)} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, marginTop: 20 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.street}</p>
                                        <TextField error={streetErr == '' ? false : true} helperText={streetErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={street} onInput={e => setStreet(e.target.value)} />
                                    </div>
                                </div>
                            </div>


                            {/* PSC, MESTO */}
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                <div style={{ ...styles.BlockRow, width: '90%' }}>
                                    <div style={{ ...styles.BlockLeft, width: '30%', marginRight: 10 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.psc}</p>
                                        <TextField error={pscErr == '' ? false : true} helperText={pscErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={psc} onInput={e => setPsc(e.target.value)} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '70%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.town}</p>
                                        <TextField error={townErr == '' ? false : true} helperText={townErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={town} onInput={e => setTown(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            {/* ICO, DIC */}
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                <div style={{ ...styles.BlockRow, width: '90%' }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', marginRight: 10 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.ico}</p>
                                        <TextField error={icoErr == '' ? false : true} helperText={icoErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={ico} onInput={e => setICO(e.target.value)} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.dic}</p>
                                        <TextField error={dicErr == '' ? false : true} helperText={dicErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={dic} onInput={e => setDIC(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            {/* MOBIL, EMAIL */}
                            <div style={{ ...styles.Block, marginTop: 40, backgroundColor: global.theme_back, paddingTop: 40, paddingBottom: 20 }}>
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextLarge }}>Kontaktné údaje</p>
                                </div>
                                <div style={{ ...styles.BlockRow, width: '90%', marginTop: 20 }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', marginRight: 10 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.mobil}</p>
                                        <TextField error={mobilErr == '' ? false : true} helperText={mobilErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.email}</p>
                                        <TextField error={emailErr == '' ? false : true} helperText={emailErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                {/* POZNAMKA */}

                                <div style={{ width: '90%', marginTop: 20 }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>poznámka k objednávke</p>
                                    <TextField rows={2} multiline={true} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} />
                                </div>

                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    <ReCAPTCHA
                                        sitekey="6LcBgIQeAAAAAMk_qe_tHfZjpY0vOuhtfxP1RtgP"
                                        onChange={onChangeCaptcha}
                                    />
                                </div>

                            </div>



                            <div style={{ ...styles.Block, marginTop: 20, marginBottom: 20 }}>
                                <div style={{ width: '90%' }}>

                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>Po obdržaní objednávky Vám zašleme faktúru na Vášu e-mailovú adresa. Po jej úhrade bude Váš účet aktivovaný.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    :
                    <div style={{ ...styles.Block, width: '100%', paddingTop: 50, paddingBottom: 50 }}>
                        {/* BODY */}
                        <div style={{ ...styles.Block, width: '100%', height: '100%', backgroundColor: global.theme_white }}>
                            <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                                <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                            </div>
                            <p style={{ ...styles.TextLarge, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>Požiadavka bola úspešne odoslaná.</p>
                            <p style={{ ...styles.TextSmall, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>Na Váš e-mail Vám pošleme inštrukcie k spusteniu aplikácie.</p>
                        </div>

                    </div>
                }

                <div style={{ ...styles.Block, width: '100%' }}>
                    <div style={{}}>
                        {isBusy == true ?
                            <CircularProgress color="inherit" size={20} />
                            : error != '' ?
                                <Alert severity="error">
                                    {error}
                                </Alert>
                                : null
                        }
                    </div>
                    <div style={{ ...styles.Block, paddingBottom: 20 }}>
                        {sended == false ?
                            <div style={{ ...styles.Block, paddingTop: 20 }}>
                                <Button onClick={() => Send()} style={{ ...styles.ButtonGreen, margin: 10, width: 220 }}>{lang.send_request}</Button>
                                <Button onClick={() => navigate('/')} style={{ ...styles.ButtonLink, margin: 10, width: 220 }}>{lang.back_to_home}</Button>
                            </div>
                            :
                            <Button onClick={() => navigate('/')} style={{ ...styles.ButtonGreen, margin: 10, width: 220 }}>{lang.back_to_home}</Button>
                        }
                    </div>
                </div>
            </div >
        </div>
    )
}