import React, { useEffect, useState, useRef, useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Button, Paper } from '@mui/material';
import { sk } from './globals.js';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome, useScrollDirection } from './items.js';
import { Block } from '@mui/icons-material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { faCalendar, faCalendarCheck, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const Main = (props) => {
    const navigate = useNavigate();

    const [language, setLanguage] = useState(0);
    const [showOrder, setShowOrder] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [price1, setPrice1] = useState('');
    const [price2, setPrice2] = useState('');
    const [price3, setPrice3] = useState('');

    const [play, setPlay] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const photo_tablets = require('./react/app/tablet_phone_2.jpg');
    const photo_notebook = require('./react/app/notebook_2.png');
    const photo_1 = require('./react/app/photo_1.jpg');
    const photo_2 = require('./react/app/photo_2.jpg');
    const photo_3 = require('./react/app/photo_3.jpg');
    const photo_4 = require('./react/app/photo_4.jpg');
    const photo_5 = require('./react/app/photo_5.jpg');
    const photo_6 = require('./react/app/photo_6.jpg');
    const photo_7 = require('./react/app/photo_7.jpg');
    const image_video = require('./react/app/video_play.jpg');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    var lang = sk;
    let { func } = props;


    useEffect(() => {
        func(true, 0); // page index
        GoHome();
        db_web();

        return () => {
        };

    }, []);

    const db_web = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                var tmp = json.price_month;
                tmp = tmp.toString().replace('.', ',');
                setPrice1(tmp);

                var tmp = json.price_year;
                tmp = tmp.toString().replace('.', ',');
                setPrice2(tmp);

                var tmp = json.price_year2;
                tmp = tmp.toString().replace('.', ',');
                setPrice3(tmp);
            }

            setBusy(false);

        } catch (error) {
            //Debug(error);
            setBusy(false);
        }
    }

    return (
        <div style={{ ...styles.Block, position: 'relative', marginTop: 0, zIndex: 100 }}>

            <div style={{ ...styles.Block, backgroundColor: global.theme_back, paddingTop: 40, paddingBottom: 0, marginBottom: height }}>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <h1 style={{ ...styles.TextLabel }}>Vystavovanie žiadostí a vyskladňovacích listov jednoducho, na pár klikov.</h1>
                    <h2 style={{ ...styles.TextNormal, fontWeight: 'normal', marginTop: 5 }}>Posuňte Vašu pestovateľská pálenicu na výššiu úroveň. Doprajte si komfort pri vypĺňaní žiadostí a daňových dokumentov.</h2>
                </div>

                <div style={{ marginTop: 40 }}>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                        <img src={photo_tablets} style={{ width: isSmall ? 300 : isMedium ? 450 : 600 }}></img>
                    </AnimationOnScroll>
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.Block, width: isSmall ? '95%' : '80%' }}>
                    <a href={global.web + '/admins/register'} target="_blank" style={{ ...styles.Button, ...styles.Block, color: global.theme_white, backgroundColor: global.theme_dark_red, textDecoration: 'none', width: 200, height: 50, marginTop: 40 }}>
                        {lang.demo.toUpperCase()}
                    </a>
                </div>

                <div style={{ ...styles.Block, marginTop: 80 }}>
                    {play == false ?
                        <img onClick={() => setPlay(true)} src={image_video} style={{ width: '100%', height: '100%', maxWidth: isSmall ? width - 40 : 640, maxHeight: 360, objectFit: 'contain', borderRadius: 20, cursor: 'pointer' }}></img>
                        :
                        <video id='ending-video' autoPlay playsInline controls style={{ maxWidth: isSmall ? width - 40 : 640, maxHeight: 360, borderRadius: 20 }}>
                            <source src={global.web + '/public/slovenska_palenica.mp4'} type="video/mp4" />
                        </video>
                    }
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_back, paddingTop: 80, paddingBottom: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%', paddingRight: isSmall ? 0 : 10, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_medium }}>ekologické riešenie</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Online riešenie pre pestovateľské pálenice</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'left', color: global.theme_dark_gray }}>Vystavujte doklady pre zákazníkov a colný úrad pohodlne na svojom počítači, tablete alebo mobilnom telefóne. Žiadna inštalácia, aplikácia sa spúšťa priamo vo Vašom prehliadači.</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                            <img src={photo_2} style={{ width: '100%' }}></img>
                        </AnimationOnScroll>
                    </div>
                </div>


                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_white, paddingTop: 50, paddingBottom: 50 }}>
                    {isSmall ? null :
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%' }}>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <img src={photo_1} style={{ width: '100%' }}></img>
                            </AnimationOnScroll>
                        </div>
                    }
                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : '50%', paddingLeft: isSmall ? 0 : 10, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_blue, color: global.theme_medium }}>moderná evidencia</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Urobte si poriadok na pracovnom stole</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 15, textAlign: 'left', color: global.theme_dark_gray }}>Prejdite na digitálnu správu žiadostí a dokladov svojej pálenice. Už žiadne hľadanie. Všetko je evidované v online systéme, ku ktorému máte prístup odkiaľkovek.</p>
                        </div>
                    </div>
                    {isSmall ?
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <img src={photo_1} style={{ width: '100%' }}></img>
                            </AnimationOnScroll>
                        </div>
                        : null
                    }
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_back, paddingTop: 50, paddingBottom: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%', paddingRight: isSmall ? 0 : 10, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_medium }}>Jednoduché vypĺňanie</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Žiadosti a vyskladňovacie listy</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'left', color: global.theme_dark_gray }}>Vypĺňajte žiadosti o pálenie a vyskladňovacie listy jednoducho.</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                            <img src={photo_3} style={{ width: '100%' }}></img>
                        </AnimationOnScroll>
                    </div>
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_white, paddingTop: 50, paddingBottom: 50 }}>
                    {isSmall ? null :
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%' }}>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <img src={photo_4} style={{ width: '100%' }}></img>
                            </AnimationOnScroll>
                        </div>
                    }
                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : '50%', paddingLeft: isSmall ? 0 : 10, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_blue, color: global.theme_medium }}>databáza</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Zoznam zákazníkov</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 15, textAlign: 'left', color: global.theme_dark_gray }}>Databáza Vašich zákazníkov je Vám poruke. Kliknutím získate prehľadný zoznam zákazníkov s ich kontaktnými údajmi. Automatické dopĺňanie nových zákazníkov do zoznamu pri vypĺňaní žiadosti.</p>
                        </div>
                    </div>
                    {isSmall ?
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <img src={photo_4} style={{ width: '100%' }}></img>
                            </AnimationOnScroll>
                        </div>
                        : null
                    }
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_back, paddingTop: 50, paddingBottom: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%', paddingRight: isSmall ? 0 : 10, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_medium }}>pomôcky</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Alkoholometrické tabuľky</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'left', color: global.theme_dark_gray }}>Už žiadne hľadanie v tabuľkách. Výpočet objemovej hodnoty a ceny priamo v aplikácii.</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                            <img src={photo_5} style={{ width: '100%' }}></img>
                        </AnimationOnScroll>
                    </div>
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_white, paddingTop: 50, paddingBottom: 50 }}>
                    {isSmall ? null :
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%' }}>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <img src={photo_6} style={{ width: '100%' }}></img>
                            </AnimationOnScroll>
                        </div>
                    }
                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : '50%', paddingLeft: isSmall ? 0 : 10, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_blue, color: global.theme_medium }}>tlačové zostavy</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Mesačné prehľady</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 15, textAlign: 'left', color: global.theme_dark_gray }}>Všetko potrebné pre daňové a colné úrady na jednom mieste a prehľadne vo formáte PDF a EXCEL.</p>
                        </div>
                    </div>
                    {isSmall ?
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <img src={photo_6} style={{ width: '100%' }}></img>
                            </AnimationOnScroll>
                        </div>
                        : null
                    }
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_back, paddingTop: 50, paddingBottom: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%', paddingRight: isSmall ? 0 : 10, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_medium }}>pomôcky</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Plánovací kalendár</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'left', color: global.theme_dark_gray }}>Zabudovaný plánovací kalendár, ktorý máte stále so sebou, jednoducho a prehľadne organizuje Váš pracovný deň.</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                            <img src={photo_7} style={{ width: '100%' }}></img>
                        </AnimationOnScroll>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_white, paddingTop: 50, paddingBottom: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: isSmall ? '100%' : '90%' }}>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '33%', paddingBottom: 50 }}>
                            <div style={{ ...styles.BlockRow, width: '90%' }}>
                                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_dark_green, marginRight: 10 }} icon={faCheckCircle} />
                                <p style={{ ...styles.TextLarge, marginTop: 8, color: global.theme_black }}>Pravidelné aktualizácie</p>
                            </div>
                            <div style={{ marginTop: 20, width: '90%' }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Funkčnosť aplikácie pravidelne aktualizujeme a dopĺňame. Množstvo noviniek pochádza od návrhov našich zákazníkov.</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '33%', paddingBottom: 50 }}>
                            <div style={{ ...styles.BlockRow, width: '90%' }}>
                                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_dark_green, marginRight: 10 }} icon={faCheckCircle} />
                                <p style={{ ...styles.TextLarge, marginTop: 8, color: global.theme_black }}>Rýchla podpora</p>
                            </div>
                            <div style={{ marginTop: 20, width: '90%' }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Potrebujete poradiť? Napište nám - sme tu pre Vás každý deň od 9:00 hod. do 17:00 hod.</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '33%', paddingBottom: 50 }}>
                            <div style={{ ...styles.BlockRow, width: '90%' }}>
                                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_dark_green, marginRight: 10 }} icon={faCheckCircle} />
                                <p style={{ ...styles.TextLarge, marginTop: 8, color: global.theme_black }}>Bezplatná konzultácia</p>
                            </div>
                            <div style={{ marginTop: 20, width: '90%' }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Máte pripomienku, alebo potrebujete ihneď poradiť? Zavolajte nám a my Vám ochotne paradíme.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {isBusy == false ?
                    <div style={{ ...styles.Block, backgroundColor: global.theme_back, paddingTop: 50, paddingBottom: 80 }}>
                        <p style={{ ...styles.TextXXLarge, fontWeight: 'bold', marginTop: 5 }}>Cenník</p>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw, marginTop: 50 }}>
                            <Paper elevation={3} style={{ ...styles.BlockRaw, width: 320, paddingTop: 20, paddingBottom: 20, marginRight: isSmall ? 0 : 10, marginBottom: 20 }}>
                                <div style={{ ...styles.BlockRow, width: '90%' }}>
                                    <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_dark_blue, marginRight: 10 }} icon={faCalendar} />
                                    <p style={{ ...styles.TextLarge, marginTop: 8, color: global.theme_black }}>Mesačné platby</p>
                                </div>
                                <div style={{ marginTop: 20, width: '90%', height: 80 }}>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Platba na faktúru 1x mesačne.</p>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Platnosť licencie 1 mesiac</p>
                                </div>
                                <div style={{ marginTop: 20, width: '90%', height: 70 }}>
                                    <p style={{ ...styles.TextLarge, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}><b>{price1} €</b> / mesačne</p>
                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left', marginTop: 3, color: global.theme_dark_gray }}>vrátane DPH</p>
                                </div>
                                <div style={{ ...styles.BlockRight, marginTop: 20, width: '90%', height: 50 }}>
                                    <Button onClick={() => navigate('/subscribe/0')} style={{ ...styles.ButtonGreen }}>Objednať</Button>
                                </div>
                            </Paper>
                            <Paper elevation={3} style={{ ...styles.BlockRaw, width: 320, paddingTop: 20, paddingBottom: 20, marginLeft: isSmall ? 0 : 10, marginBottom: 20 }}>
                                <div style={{ ...styles.BlockRow, width: '90%' }}>
                                    <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_dark_blue, marginRight: 10 }} icon={faCalendarCheck} />
                                    <p style={{ ...styles.TextLarge, marginTop: 8, color: global.theme_black }}>Ročná platba</p>
                                </div>
                                <div style={{ marginTop: 20, width: '90%', height: 80 }}>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Platba na faktúru 1x ročne. Zvýhodnená cena vo forme 2 mesiacov zadarmo.</p>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Platnosť licencie 1 rok</p>
                                </div>
                                <div style={{ marginTop: 20, width: '90%', height: 70 }}>
                                    <p style={{ ...styles.TextLarge, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}><b>{price3} €</b> / mesačne</p>
                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left', marginTop: 3, color: global.theme_dark_gray }}>vrátane DPH</p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Platí pri platbe 1x ročne {price2} €</p>
                                </div>
                                <div style={{ ...styles.BlockRight, marginTop: 20, width: '90%', height: 50 }}>
                                    <Button onClick={() => navigate('/subscribe/1')} style={{ ...styles.ButtonGreen }}>Objednať</Button>
                                </div>
                            </Paper>
                        </div>
                    </div>
                    : null}

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_lighter, paddingTop: 80, paddingBottom: 50 }}>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%' }}>
                        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                            <img src={photo_notebook} style={{ width: '100%' }}></img>
                        </AnimationOnScroll>
                    </div>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : '50%', paddingLeft: isSmall ? 0 : 10, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <h1 style={{ ...styles.TextLabel, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Vyskúšajte zadarmo</h1>
                            <p style={{ ...styles.TextNormal, marginTop: 15, textAlign: 'left', color: global.theme_dark_gray }}>Zaregistrujte sa a vyskúšajte 7 dňovú bezplatnú verziu práve teraz. Bez inštalácii a nezáväzne.</p>
                            <a href={global.web + '/admins/register'} target="_blank" style={{ ...styles.Button, ...styles.Block, color: global.theme_white, backgroundColor: global.theme_dark_red, textDecoration: 'none', width: 200, height: 50, marginTop: 40 }}>
                                {lang.demo.toUpperCase()}
                            </a>
                        </div>
                    </div>
                </div>

                {/*
            <h1 style={{ ...styles.TextLabel }}>Online riešenie pre pestovateľské pálenice</h1>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRow, marginTop: 50 }}>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%', paddingRight: isSmall ? 0 : 10 }}>
                    <p>demo</p>
                </div>
                <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%' }}>
                    <img src={photo_1} style={{ width: '100%' }}></img>
                </div>
            </div>
        */}
            </div >

        </div>
    )
}